/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { ReactElement, useEffect, useState } from 'react';

export type BlankLayoutProps = {
  children: ReactElement<any, any>;
  pageName: string;
};

const PlaceHolderLayout = ({ children, pageName }: BlankLayoutProps) => {
  let image: string;

  const getCurrentPageName = () => {
    switch (pageName) {
      case 'Buy Admission':
      case 'Reservation':
        image = 'https://sojospaclub.com/wp-content/uploads/2022/12/Book-Admission';
        break;
      case 'Checkout':
        image = 'https://sojospaclub.com/wp-content/uploads/2023/01/shop_feature';
        break;
      case 'Shop':
        image = 'https://sojospaclub.com/wp-content/uploads/2023/05/rooftopgarden3';
        break;
      case 'Order Cancel':
      case 'Cancel Spa Treatment':
        image = 'https://sojospaclub.com/wp-content/uploads/2023/01/shop_feature';
        break;
      default:
        image = 'https://sojospaclub.com/wp-content/uploads/2022/12/sojo_spa_093';
    }

    return pageName;
  };
  const pageTitle = getCurrentPageName();

  return (
    <div className="entry-content">
      <div className="sm2-marquee sm2-marquee--short">
        <div className="sm2-marquee__content ">
          <h2
            className="sm2-marquee__head"
            data-sr-id="0"
            style={{
              visibility: 'visible',
              opacity: 1,
              transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
              transition: 'opacity 1.5s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.5s cubic-bezier(0.5, 0, 0, 1) 0s',
            }}
          >
            {pageTitle}
          </h2>
          <div
            className="divider-light mx-auto my-4 md:my-5"
            data-sr-id={6}
            style={{
              visibility: 'visible',
              opacity: 1,
              transition: 'opacity 1.5s cubic-bezier(0.5, 0, 0, 1) 0s',
            }}
          />
          <div className="sm2-marquee__arrow"></div>
        </div>

        <div className="sm2-marquee__media">
          <img loading="lazy" width="1600" height="1068" src={`${image}.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="" decoding="async" />
        </div>
      </div>
      {children}
    </div>
  );
};

export default PlaceHolderLayout;
