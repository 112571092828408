import { GetServerSideProps } from 'next';
import { wrapper } from '../store';
import { Product } from '../types/product';
import Index from './gift-certificate';
import { getServerSideProps as giftCertificateGetServerSideProps } from './gift-certificate';

export const getServerSideProps: GetServerSideProps<{ data: Product[] }> = wrapper.getServerSideProps(() => async context => {
  return giftCertificateGetServerSideProps(context);
});

export default Index;
